import './TrinityButton.scss'
import { dispatch } from 'api/redux'
import { showSnackbar } from 'components/snackbar'

interface TrinityButtonProps {
  children: any
  onClick: () => void
  active?: boolean
  className?: string
  loading?: boolean
  disabled?: boolean
  disabledMessage?: string
  size?: string
}

export const TrinityButton = ({ children, active, className, onClick, loading, disabled, disabledMessage, size }: TrinityButtonProps) => {
  const handleClick = () => {
    if (disabled) {
      if (!disabledMessage) {
        throw new Error('Disabled message is required when button is disabled')
      }
      dispatch(showSnackbar(disabledMessage, 'error'))
    } else {
      onClick()
    }
  }
  return (
    <button
        className={`trinity-toggle-button 
          ${active ? 'active' : ''}
          ${size ?? ''}
          ${className ?? ''}
          ${disabled ? 'disabled' : ''}
        `}
        onClick={handleClick}
    >
      <div>
        {loading ? <div className={'spinner'} /> : children}
      </div>
    </button>
  )
}
