import { mainSplitApi } from './redux/mainSplitApi'

export const featureToggleApi = mainSplitApi.injectEndpoints({
  endpoints: (builder) => ({
    getFlag: builder.query<boolean, string>({
      query: (flagName) => {
        return {
          url: `/feature-toggles/${flagName}`,
          method: 'GET'
        }
      }
    })
  }),
  overrideExisting: false
})

export const { useGetFlagQuery } = featureToggleApi
