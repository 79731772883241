import { Divider } from '@nike/eds'
import { type ReactElement } from 'react'

interface MainPanelProps {
  leftPanelContent: ReactElement
  mainPanelContent: ReactElement
  rightPanelContent?: ReactElement
}

export const MainPanel = ({ leftPanelContent, mainPanelContent, rightPanelContent }: MainPanelProps) => {
  return (
    <div className={`unithandling flex ${rightPanelContent ? 'justify-between' : ''}`}>
      <div id={'leftPanel'} className='flex w-2/5'>
        {leftPanelContent}
        <Divider vertical={true} className='bg-black p-px' />
      </div>
      <div id={'mainPanel'} className={`relative flex flex-grow justify-around w-${rightPanelContent ? '2' : '3'}/5 px-6`}>
        {mainPanelContent}
      </div>
      {rightPanelContent &&
        <div id={'rightPanel'} className='flex justify-around w-1/5'>
          <Divider vertical={true} className='bg-black p-px' />
          {rightPanelContent}
        </div>
      }
    </div>
  )
}
