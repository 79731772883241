
import { type Unit, type LabelType, type PrintLabelResponse } from 'types'

import { mainSplitApi } from './redux/mainSplitApi'

export const unitApi = mainSplitApi.injectEndpoints({
  endpoints: (builder) => ({
    createUnit: builder.mutation<Unit, { unitBarcode: string, lpn: string }>({
      query: ({ unitBarcode, lpn }) => {
        return {
          url: `/units?upc=${unitBarcode}${lpn && `&lpn=${lpn}`}`,
          method: 'POST'
        }
      }
    }),

    updateUnit: builder.mutation<Unit, Unit>({
      query: (unit) => {
        return {
          url: '/units',
          method: 'PUT',
          body: unit
        }
      }
    }),

    printUnitLabel: builder.mutation<PrintLabelResponse, { unitId: string, labelType: LabelType }>({
      query: ({ unitId, labelType }) => {
        return {
          url: `/units/${unitId}/label?type=${labelType}`,
          method: 'POST'
        }
      }
    })
  }),
  overrideExisting: false
})

export const { useCreateUnitMutation, useUpdateUnitMutation, usePrintUnitLabelMutation } = unitApi
