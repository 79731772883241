import { Text } from '@nike/eds'
import { TrinityButton } from 'components/trinity-button'

interface MainGradesProps {
  handleGradeChange: (newGrade: string) => void
  activeGrade: string
  hashable: boolean
}

export const MainGradeButtons = ({ handleGradeChange, activeGrade, hashable }: MainGradesProps) => {
  return <>
    <Text font={'display-2'} as={'h1'} className={'mb-6'}>
      Grade
    </Text>

    <div className={'button-grid'}>
      {hashable
        ? <TrinityButton className={'grid-button full-left background-blue'}
          active={activeGrade === 'Hash'}
          size={'massive'}
          onClick={() => { handleGradeChange('Hash') }}>
          Hash
        </TrinityButton>
        : <TrinityButton className={'grid-button full-left background-green'}
          active={activeGrade === 'A-grade'}
          size={'massive'}
          onClick={() => { handleGradeChange('A-grade') }}>
          A-grade
        </TrinityButton>
      }
      <TrinityButton className={'grid-button background-orange'}
        active={activeGrade === 'B-grade'}
        size={'massive'}
        disabled={hashable || true}
        disabledMessage={hashable ? 'Hash product cannot be B-graded.' : 'B-grade not implemented.'}
        onClick={() => { handleGradeChange('B-grade') }}>
        B-grade
      </TrinityButton>
      <TrinityButton className={'grid-button background-red'}
        active={activeGrade === 'C-grade'}
        size={'massive'}
        onClick={() => { handleGradeChange('C-grade') }}>
        C-grade
      </TrinityButton>
    </div>
  </>
}
