export const CGradeReason: Record<string, string> = {
  '020': 'DIRTY',
  '090': 'DIRTY SOLES',
  '016': 'DAMAGED',
  '050': 'WET',
  '010': 'DAMAGED BY SECURITY TAG',
  '011': 'SECURITY TAG (PRESENCE)',
  '021': 'DIGITAL – POOR QUALITY',
  '030': 'DISCOLOURED',
  '040': 'BROKEN PRE-PACK',
  '060': 'WRONG CONTENT',
  '070': 'CUSTOMIZED UNITS',
  '081': 'AMERICAN SKU'
}
