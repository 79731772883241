/*
 * Custom hook used to abstract common logic for making API calls during unit handling.
 * This prevents a **lot** of useEffects and state management within the UnitHandling.tsx file
 * This handles:
 * - Loading state
 * - Executing actions on failure or success
 * - Exposing the API call's result
 * Uses TArgs and TResult TypeScript generics to ensure type safety by validating usage at compile time
 */

import { useEffect, useState } from 'react'

interface ApiHandlerProps<TArgs, TResult> {
  apiFunction: (args: TArgs) => Promise<TResult>
  successAction: (result: TResult) => void
  failureAction: (error: any) => void
  displayLoading?: boolean
}

interface ApiHandlerContext<TArgs> {
  handleApiCall: (args: TArgs) => Promise<void>
  isLoading: boolean
  showLoadingOverlay: boolean
}

const useApiHandler = <TArgs, TResult>({
  apiFunction,
  successAction,
  failureAction,
  displayLoading = true
}: ApiHandlerProps<TArgs, TResult>) => {
  const [isLoading, setIsLoading] = useState(false)
  const [showOverlay, setShowOverlay] = useState(false)

  useEffect(() => {
    setShowOverlay(isLoading && displayLoading)
  }, [isLoading, displayLoading])

  const handleApiCall = async (args: TArgs) => {
    setIsLoading(true)
    try {
      const result = await apiFunction(args)

      if (result) {
        successAction(result)
      } else {
        throw new Error('No result returned')
      }
    } catch (error) {
      failureAction(error)
    } finally {
      setIsLoading(false)
    }
  }
  const returnValue: ApiHandlerContext<TArgs> = { handleApiCall, isLoading, showLoadingOverlay: showOverlay }
  return returnValue
}

export { useApiHandler, type ApiHandlerContext }
