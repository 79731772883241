import { Snack, Snackbar, StatusIcon } from '@nike/eds'
import { dispatch, useAppSelector } from 'api/redux'
import { useEffect, useState } from 'react'

import { showSnackbar } from './snackbar.action'
import { type SnackbarState, type SnackbarStatus } from './snackbar.slices'
import './snackbar.css'

export const SnackBar = () => {
  const snackbarState = useAppSelector((state: { snackbar: SnackbarState }) => state.snackbar)
  const [message, setMessage] = useState('')
  const [status, setStatus] = useState<SnackbarStatus>('neutral')
  let timerHandle: string | number | NodeJS.Timeout | undefined

  useEffect(() => {
    if (snackbarState.message !== '') {
      setMessage(snackbarState.message)
      setStatus(snackbarState.status)
      timerHandle = setTimeout(() => {
        reset()
      }, snackbarState.timeout ?? 12000)
    }
    return () => {
      clearTimeout(timerHandle)
    }
  }, [snackbarState])

  const reset = () => {
    setMessage('')
    setStatus('neutral')
    dispatch(showSnackbar('', 'neutral'))
  }

  return (
    <>
      {message !== '' &&
        <Snackbar onClick={reset}>
          {/* TODO 24/08/02: add statusIconSize L and increase message size, awaiting https://github.com/nike-enterprise-design-system/eds/pull/1227 */}
          <Snack id={'snackbar-snack'} status={status} customIcon={<StatusIcon status={status} statusIconSize={'m'} />}>
            <div className={'text-3xl'}>
              {message}
            </div>
          </Snack>
        </Snackbar>
      }
    </>
  )
}
