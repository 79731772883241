import { Text } from '@nike/eds'
import { TrinityButton } from 'components/trinity-button'
import { useMemo } from 'react'
import { CGradeReason } from 'types/CGradeReason'

interface CGradeButtonsProps {
  gradeReason: { code: string, reason: string }
  setGradeReason: (gradeReason: { code: string, reason: string }) => void
}

export const CGradeButtons = ({ gradeReason, setGradeReason }: CGradeButtonsProps) => {
  const cGradeButtons = useMemo(() => {
    return Object.keys(CGradeReason).map((key) => {
      const isActive = gradeReason.code === key
      return (
        <TrinityButton key={key} active={isActive} onClick={() => {
          setGradeReason({ code: key, reason: CGradeReason[key] })
        }}>
            {CGradeReason[key]}
        </TrinityButton>
      )
    })
  }, [gradeReason.code, setGradeReason])
  return <>
    <Text font={'display-2'} as={'h1'} className={'mb-6'}>
      C-Grade reason
    </Text>
    <div className="cgrade-button-group-container">
      {cGradeButtons}
    </div>
  </>
}
