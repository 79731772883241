import { type UserDetails } from 'types'

import { mainSplitApi } from './redux/mainSplitApi'

export const authApi = mainSplitApi.injectEndpoints({
  endpoints: (builder) => ({
    whoAmI: builder.query<UserDetails, void>({
      query: () => {
        return {
          url: 'authenticate/whoami',
          method: 'GET'
        }
      }
    }),
    logout: builder.mutation<void, void>({
      query: () => {
        return {
          url: 'authenticate/logout',
          method: 'POST'
        }
      }
    })
  }),
  overrideExisting: false
})

export const { useWhoAmIQuery, useLogoutMutation } = authApi
