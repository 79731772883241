import { propagation, context } from '@opentelemetry/api'
import { createApi } from '@reduxjs/toolkit/query/react'

import { customBaseQuery } from './CustomBaseQuery'

export const mainSplitApi = createApi({
  reducerPath: 'mainSplitApi',
  baseQuery: customBaseQuery({
    baseUrl: process.env.REACT_APP_API_URL,
    // Linking of HTTP Session happens on request cookie SESSION, not JSESSIONID, example header:
    // -H 'Cookie: JSESSIONID=0B34937E208FE6B3769A7625FB06D7AA; SESSION=MDU5YTRhMjUtOWEzYy00MzQ0LTlhZjQtNDVjNTU5YjU0MTY5' \
    credentials: 'include',
    // Add OpenTelemetry trace context to headers
    // Also requires CustomBaseQuery to pass these headers
    prepareHeaders: (headers) => {
      const activeContext = context.active()
      if (context) {
        propagation.inject(activeContext, headers, {
          set: (carrier, key, value) => {
            carrier.set(key, value)
          }
        })
      }
      return headers
    }
  }),
  endpoints: (builder) => ({})
})
