import { NikeApp } from '@nike/nike-design-system-icons'
import './Footer.css'

export function Footer () {
  return (
    <div className="footer">
      <NikeApp
        title="Nike logo"
        color="black"
        width="56px"
        height="32px"
      />
    </div>
  )
}
