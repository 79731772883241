import { Icon, TextField } from '@nike/eds'
import { usePrintRasnLpnLabelMutation } from 'api/rasn'
import { dispatch } from 'api/redux'
import { RasnRlpnDetailsPanel } from 'components/left-detail-panel'
import { MainPanel } from 'components/main-panel'
import { NumPad } from 'components/num-pad'
import { showSnackbar } from 'components/snackbar'
import { TrinityButton } from 'components/trinity-button'
import { useEffect, useState } from 'react'
import { LabelType, type RASN, type PrintLabelResponse } from 'types'
import { useApiHandler, type ApiHandlerContext } from 'utils/useApiHandler'
import { v4 as uuidv4 } from 'uuid'

import './ScanUnit.css'

interface ScanUnitProps {
  goodsHolder: string | undefined
  rasnData: RASN
  processedQuantity: number
  createUnitContext: ApiHandlerContext<string>
  finishRasnContext: ApiHandlerContext<string>
  handlePauseCarton: () => void
  closeGoodsHolderContext: ApiHandlerContext<string>
  showBlindIlpn: boolean
}
const ITEM_MAX_LENGTH = 19

export const ScanUnit = ({ goodsHolder, rasnData, processedQuantity, createUnitContext, finishRasnContext, handlePauseCarton, closeGoodsHolderContext, showBlindIlpn }: ScanUnitProps) => {
  const [error, setError] = useState('')
  const [upcBarcode, setUpcBarcode] = useState('')
  const [showNumPad, setShowNumPad] = useState(false)

  const [printILPNLabel] = usePrintRasnLpnLabelMutation()

  const printLabelContext = useApiHandler({
    apiFunction: async (labelType: LabelType) => await printILPNLabel({ rasnNumber: rasnData.number, labelType }).unwrap(),
    successAction: (response: PrintLabelResponse) => {
      const message = response.status === 'OK' ? 'Successfully printed label' : 'Failed to print label'
      const status = response.status === 'OK' ? 'success' : 'error'
      dispatch(showSnackbar(message, status))
    },
    failureAction: () => {
      dispatch(showSnackbar('Failed to print label', 'error'))
    }
  })

  useEffect(() => {
    if (upcBarcode.length > ITEM_MAX_LENGTH) {
      setError('UPC is too long')
    } else {
      setError('')
    }
  }, [upcBarcode])

  const keyUpHandler = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      handleConfirm()
    }
  }

  const handleConfirm = () => {
    if (error === '' && upcBarcode.length > 0 && !createUnitContext.isLoading) {
      setUnitId()
      createUnitContext.handleApiCall(upcBarcode)
        .then()
        .catch(err => {
          if (err.status !== 404) throw err // this case should never be reached, if so it's a bug so throw
          dispatch(showSnackbar(`Unknown UPC ${upcBarcode}`, 'error'))
        })
      setUpcBarcode('')
    }
  }

  const leftPanelContent = <RasnRlpnDetailsPanel goodsHolder={goodsHolder} rasnData={rasnData} processedQuantity={processedQuantity} finishRasnContext={finishRasnContext} closeGoodsHolderContext={closeGoodsHolderContext} handlePauseCarton={handlePauseCarton} />

  const mainPanelContent =
    <div style={{ display: '-webkit-box' }}>
      {showBlindIlpn && (
        <TrinityButton
          loading={printLabelContext.isLoading}
          className='absolute top-0 right-0'
          onClick={() => { printLabelContext.handleApiCall(LabelType.BlindILPN) }}
        >
          Print lpn label
        </TrinityButton>
      )}

      <div style={{ width: '900px' }} className={'justify-around m-auto scanTitle'}>
        <TextField
          id='upcBarcode'
          label='Please enter the UPC:'
          placeholder='Barcode'
          value={upcBarcode}
          onChange={(e) => { setUpcBarcode(e.currentTarget.value) }}
          autoFocus
          maxChars={ITEM_MAX_LENGTH}
          hasErrors={error !== ''}
          errorMessage={error}
          onKeyUp={keyUpHandler}
          onBlur={(e) => { e.target.focus() }}
          beforeSlot={<Icon name='Scan' />}
        />
        <NumPad text={upcBarcode} setText={setUpcBarcode} showNumPad={showNumPad} setShowNumPad={setShowNumPad} confirm={handleConfirm} className='absolute w-[900px] mb-5' />
      </div>
    </div>

  return <MainPanel leftPanelContent={leftPanelContent} mainPanelContent={mainPanelContent} />
}

const setUnitId = () => {
  // Backend requires a 12 byte hexademical ID, just like RF ID
  localStorage.setItem('unitId', uuidv4().replace(/-/g, '').substring(0, 24))
}
