import { AppHeader, Avatar, IconButton } from '@nike/eds'
import { NikeApp } from '@nike/nike-design-system-icons'
import { useWhoAmIQuery } from 'api/authentication'
import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'

interface HeaderProps {
  logout: () => void
  isLoggedOut: boolean
}

export function Header ({ logout, isLoggedOut }: HeaderProps) {
  const { data: user, isLoading } = useWhoAmIQuery()
  const [initials, setInitials] = useState('-')

  useEffect(() => {
    if (isLoading || !user || !user.isAuthenticated) {
      return
    }
    setInitials(user.firstName[0] + user.lastName[0])
  }, [user])

  useEffect(() => {
    if (isLoggedOut) {
      setInitials('-')
      window.dispatchEvent(new Event('logout'))
    }
  }, [isLoggedOut])

  return (
    <AppHeader style={{ height: '48px' }}
      isDark
      appName='Uninety'
      logoSlot={
        <Link reloadDocument to='/' aria-label='logo-link'>
          <NikeApp
            title='Nike logo'
            color={'white'}
            width={'56px'}
            height={'64px'}
            style={{ marginTop: '6px' }}
          />
        </Link>
      }
      toolbarSlot={
        <div></div>
      }
      avatarSlot={
        <>
          <Avatar initials={initials} size={36} />
          <IconButton
            icon="Close"
            label="quit"
            variant="secondary"
            size="small"
            onClick={() => { logout() }}
            className="eds-color--text-secondary"
          />
        </>
      }
    />
  )
}
