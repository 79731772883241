import '@nike/eds/dist/index.css'
import { useLogoutMutation } from 'api/authentication'
import { Footer } from 'components/footer'
import { Header } from 'components/header'
import { SnackBar } from 'components/snackbar'
import { Unauthorized } from 'pages/unauthorized'
import { UnitHandling } from 'pages/UnitHandling'
import { useIdleTimer } from 'react-idle-timer'
import { Route, Routes } from 'react-router-dom'
import { useAuthentication } from 'utils/useAuthentication'

import './App.css'

function App () {
  const isAuthenticated = useAuthentication()
  const [logout, { isSuccess: isLoggedOut }] = useLogoutMutation()
  useIdleTimer({
    onIdle: () => {
      logout()
    },
    timeout: 1000 * 60 * 40
  })

  return (
      <>
        <Header logout={() => { logout() }} isLoggedOut={isLoggedOut} />
        <SnackBar />
        {isAuthenticated &&
          <Routes>
            <Route path="/*" element={<UnitHandling />} />
          </Routes>
        }
        {!isAuthenticated &&
          <Unauthorized />
        }
        <Footer />
    </>
  )
}

export default App
