import { ZoneContextManager } from '@opentelemetry/context-zone'
import { W3CTraceContextPropagator } from '@opentelemetry/core'
import { OTLPTraceExporter } from '@opentelemetry/exporter-trace-otlp-http'
import { registerInstrumentations } from '@opentelemetry/instrumentation'
import { FetchInstrumentation } from '@opentelemetry/instrumentation-fetch'
import { XMLHttpRequestInstrumentation } from '@opentelemetry/instrumentation-xml-http-request'
import { Resource } from '@opentelemetry/resources'
import { SimpleSpanProcessor } from '@opentelemetry/sdk-trace-base'
import { WebTracerProvider } from '@opentelemetry/sdk-trace-web'

const setupTracer = () => {
  const provider = new WebTracerProvider({
    resource: new Resource({
      'service.name': 'frontend',
      app_group: 'nike-uninety',
      environment: process.env.REACT_APP_OTEL_ENVIRONMENT
    })
  })

  const traceExporter = new OTLPTraceExporter({
    url: process.env.REACT_APP_OTEL_TRACES_ENDPOINT
  })

  provider.addSpanProcessor(new SimpleSpanProcessor(traceExporter))

  provider.register({
    // ZoneContextManager supports asynchronous operations
    contextManager: new ZoneContextManager(),
    propagator: new W3CTraceContextPropagator()
  })

  registerInstrumentations({
    tracerProvider: provider,
    instrumentations: [
      new XMLHttpRequestInstrumentation(),
      new FetchInstrumentation()
    ]
  })
}

export default setupTracer
