import { useWhoAmIQuery } from 'api/authentication'
import { dispatch } from 'api/redux'
import { showSnackbar } from 'components/snackbar'
import { useEffect } from 'react'

const validDPIVersions = ['200', '300']

export const useAuthentication = () => {
  const { data: user, isError, isLoading, refetch } = useWhoAmIQuery()
  const UNIT_HANDLING_STATION_REQUEST_PARAM = 'unitHandlingStation'
  const DPI_VERSION_REQUEST_PARAM = 'dpiVersion'
  const params = new URLSearchParams(window.location.search)

  useEffect(() => {
    if (isLoading || isError) {
      return
    }
    if (!user) {
      // If user would be undefined due to any other issues, force a refetch
      refetch()
      return
    }

    if (!user.isAuthenticated) {
      try {
        redirectToAuthenticationEndpoint()
      } catch (e: any) {
        dispatch(showSnackbar(e.message, 'error'))
      }
    }
  }, [user, isError, isLoading])

  const redirectToAuthenticationEndpoint = () => {
    const missingParams = [UNIT_HANDLING_STATION_REQUEST_PARAM].filter(param => !params.has(param))
    if (missingParams.length > 0) {
      throw new Error(`The hyperlink used to start Uninety is not correctly configured: the ${missingParams.join(' and ')} parameter${missingParams.length > 0 ? 's' : ''} is missing`)
    }

    const station = params.get(UNIT_HANDLING_STATION_REQUEST_PARAM)!
    const dpiVersion = params.get(DPI_VERSION_REQUEST_PARAM) ?? '300'
    if (!validDPIVersions.includes(dpiVersion)) {
      throw new Error(`The DPI printer version used to start Uninety is not correctly configured: must be 200 or 300 but is ${dpiVersion}`)
    }
    window.location.assign(`${process.env.REACT_APP_API_URL!}/authenticate?redirectUrl=${encodeURIComponent(window.location.href)}&unitHandlingStation=${encodeURIComponent(station)}&dpiVersion=${encodeURIComponent(dpiVersion)}`)
  }

  const isAuthenticated = () => user !== undefined && params.has(UNIT_HANDLING_STATION_REQUEST_PARAM)
  return isAuthenticated()
}
