import { type AppDispatch } from 'api/redux'
import { actions, type SnackbarStatus } from 'components/snackbar/snackbar.slices'

export const showSnackbar = (message: string, status: SnackbarStatus, timeout?: number) =>
  async (dispatch: AppDispatch): Promise<void> => {
    dispatch(actions.showSnackbar({ message, status, timeout }))
  }

export const hideSnackbar = () =>
  async (dispatch: AppDispatch): Promise<void> => {
    dispatch(actions.showSnackbar({ message: '', status: 'neutral' }))
  }
