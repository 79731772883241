import { type GoodsHolderData } from 'types'

import { mainSplitApi } from './redux/mainSplitApi'

export const goodsholderApi = mainSplitApi.injectEndpoints({
  endpoints: (builder) => ({
    openGoodsHolder: builder.mutation<GoodsHolderData, string>({
      query: (lpn) => {
        return {
          url: `/goodsholders/${lpn}`,
          method: 'POST'
        }
      }
    }),
    closeGoodsHolder: builder.mutation<GoodsHolderData, string>({
      query: (lpn) => {
        return {
          url: `/goodsholders/${lpn}/close`,
          method: 'POST'
        }
      }
    }),
    getGoodsHolderProcessedQuantity: builder.query<GoodsHolderData, string>({
      query: (lpn) => {
        return {
          url: `/goodsholders/${lpn}`,
          method: 'GET'
        }
      }
    })
  }),
  overrideExisting: false
})

export const { useOpenGoodsHolderMutation, useCloseGoodsHolderMutation, useLazyGetGoodsHolderProcessedQuantityQuery } = goodsholderApi
