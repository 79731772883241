import { Button } from '@nike/eds'
import { UnitDetailsPanel } from 'components/left-detail-panel'
import { MainPanel } from 'components/main-panel'
import { useEffect, useState } from 'react'
import { type Unit } from 'types'
import { type ApiHandlerContext } from 'utils/useApiHandler'

import './Grading.scss'
import './transitions.css'
import { CGradeButtons } from './CGradeButtons'
import { MainGradeButtons } from './MainGradeButtons'

interface GradingProps {
  unitData: Unit
  handleReturn: () => void
  updateUnitGradeContext: ApiHandlerContext<Unit>
  useHash: boolean
}

interface GradeReason {
  code: string
  reason: string
}

export const Grading = ({ unitData, handleReturn, updateUnitGradeContext, useHash }: GradingProps) => {
  const [grade, setGrade] = useState<string>('')
  const [gradeReason, setGradeReason] = useState<GradeReason>({ code: '', reason: '' })
  const [clicked, setClicked] = useState(false)
  const [displayMainGrades, setDisplayMainGrades] = useState(true)

  // If the unit is already graded the user came from a next screen
  // Displaying the grade and reason which was previously selected
  useEffect(() => {
    if (unitData.grade === 'A_GRADE') {
      setGrade('A-grade')
    } else if (unitData.grade === 'HASH') {
      setGrade('Hash')
    } else if (unitData.grade === 'C_GRADE') {
      setGrade('C-grade')
      setGradeReason({ code: unitData.isegCode, reason: unitData.gradeReason })
      setDisplayMainGrades(false)
    }
  }, [])

  // Prevents previously selected grade from triggering the grading action, required for returning from a next screen
  useEffect(() => {
    if (!clicked) return
    if (grade === 'A-grade' || grade === 'Hash' || (grade === 'C-grade' && gradeReason.code !== '')) {
      performGrade()
    }
  }, [clicked, grade, gradeReason])

  const performGrade = () => {
    let updatedUnit
    switch (grade) {
      case 'A-grade':
        updatedUnit = {
          ...unitData,
          grade: 'A_GRADE',
          gradeReason: '',
          qualityCode: '01',
          isegCode: '000',
          status: 'GRADED'
        }
        break
      case 'Hash':
        updatedUnit = {
          ...unitData,
          grade: 'HASH',
          gradeReason: '',
          qualityCode: '01',
          isegCode: '000',
          status: 'GRADED'
        }
        break
      case 'C-grade':
        updatedUnit = {
          ...unitData,
          grade: 'C_GRADE',
          qualityCode: '02',
          isegCode: gradeReason?.code,
          gradeReason: gradeReason?.reason,
          status: 'GRADED'
        }
        break
      default:
        throw new Error('Invalid grade:' + grade)
    }
    if (!updateUnitGradeContext.isLoading) {
      updateUnitGradeContext.handleApiCall(updatedUnit).then()
    }
  }

  const handleGradeClick = (newGrade: string) => {
    setGrade(newGrade)
    if (newGrade === 'C-grade') {
      setDisplayMainGrades(false)
    } else {
      setGradeReason({ code: '', reason: '' })
      setClicked(true)
    }
  }

  const handleCGradeReasonClick = (reason: { code: string, reason: string }) => {
    setGradeReason(reason)
    setClicked(true)
  }

  const leftPanelContent = <UnitDetailsPanel unit={unitData} showImage={true}/>

  const checkIfHashable = () => {
    return useHash && unitData.hashable
  }

  const mainPanelContent = <div className='flex flex-col flex-grow justify-end w-3/5'>
    {displayMainGrades
      ? (<div key={'A-grade'} className={'animated fadeInBottom mb-20'}>
            <MainGradeButtons activeGrade={grade} handleGradeChange={handleGradeClick} hashable={checkIfHashable()} />
         </div>)
      : (<div key={'C-grade'} className={'animated fadeInBottom'}>
            <CGradeButtons gradeReason={gradeReason} setGradeReason={handleCGradeReasonClick} />
         </div>)
    }
    <div className="flex justify-between w-100 mt-20">
      <Button className='text-2xl' onClick={() => {
        !displayMainGrades && grade === 'C-grade' ? setDisplayMainGrades(true) : handleReturn()
      }} variant='secondary'>
        Return
      </Button>
    </div>
  </div>

  return <MainPanel leftPanelContent={leftPanelContent} mainPanelContent={mainPanelContent} />
}
