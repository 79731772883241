import React from 'react'
import './LoadingOverlay.css'

interface LoadingOverlayProps {
  isLoading: boolean
}

export const LoadingOverlay = ({ isLoading }: LoadingOverlayProps) => {
  return (
        <div className={`loading-overlay ${isLoading ? 'show' : ''}`}>
            <div className={`spinner ${isLoading ? 'show' : ''}`} />
        </div>
  )
}
