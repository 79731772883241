import { configureStore } from '@reduxjs/toolkit'
import { reducer as snackbar } from 'components/snackbar/snackbar.slices'
import { useSelector, type TypedUseSelectorHook } from 'react-redux'

import { mainSplitApi } from './mainSplitApi'

export const store = configureStore({
  reducer: {
    snackbar,
    [mainSplitApi.reducerPath]: mainSplitApi.reducer
  },
  middleware: (getDefaultMiddleware) => {
    return getDefaultMiddleware({
    }).concat(mainSplitApi.middleware)
  }
})

export type AppDispatch = typeof store.dispatch
export type RootState = ReturnType<typeof store.getState>

export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector
export const dispatch = store.dispatch
