import { TrinityButton } from 'components/trinity-button'
import { type Unit } from 'types'
import { type LabelType, LabelType as LabelTypeEnum } from 'types/LabelType'
import { type ApiHandlerContext } from 'utils/useApiHandler'

interface ReprintProps {
  unit: Unit
  printLabelContext: ApiHandlerContext<LabelType>
}

export const Reprint = ({ unit, printLabelContext }: ReprintProps) => {
  const isApparelOrEquipment = () => {
    return unit.division === 'APPAREL' || unit.division === 'EQUIPMENT'
  }

  const isCGrade = () => {
    return unit.grade === 'C_GRADE'
  }

  const labelToPrint = isCGrade()
    ? LabelTypeEnum.CGrade
    : isApparelOrEquipment()
      ? LabelTypeEnum.UPC
      : LabelTypeEnum.Shoebox

  const buttonLabel = isCGrade() ? 'C‑grade' : isApparelOrEquipment() ? 'UPC' : 'Shoebox'

  return (
    <div className="flex flex-grow justify-center ml-6">
      <TrinityButton
          loading={printLabelContext.isLoading}
          disabled={printLabelContext.isLoading}
          size={'massive'}
          onClick={() => { printLabelContext.handleApiCall(labelToPrint) }}>
        <div className={'items-center flex flex-col'}>
          <img height={'80'} width={'80'} alt={'Printer icon'}
               src={process.env.PUBLIC_URL! + '/images/printer-icon.svg'}
               className={'mb-24'}/>
          Print {buttonLabel} label
        </div>
      </TrinityButton>
    </div>
  )
}
